export default {
  methods: {
    resetDataLayer: function(
      label,
      action = 'hit',
      event = 'Postcodecheck',
      category = 'Postcodecheck'
    ) {
      if (typeof window.dataLayer === 'undefined') {
        return
      }

      this.$store.commit('previousDataLayerLabel', null)

      window.dataLayer.push({
        event: event,
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
        eventZipCodePositive: undefined,
        eventAddressPositive: undefined,
        eventAreaStatus: undefined,
        eventKnownInAc: undefined,
        eventEnable: undefined,
        eventOrderable: undefined,
        eventScenario: undefined
      })
    },

    negativeFlowDataLayerPush: function(postalCode, data) {
      if (typeof window.dataLayer === 'undefined') {
        return
      }

      this.$store.commit('previousDataLayerLabel', 'postcode - ' + postalCode)

      window.dataLayer.push({
        event: 'Postcodecheck',
        eventCategory: 'Postcodecheck',
        eventAction: 'hit',
        eventLabel: 'postcode - ' + postalCode,
        eventZipCodePositive: false,
        eventScenario: data.flow
      })
    },

    positiveDataLayerPush: function(postalCode, data) {
      if (typeof window.dataLayer === 'undefined') {
        return
      }

      this.$store.commit('previousDataLayerLabel', 'postcode - ' + postalCode)

      window.dataLayer.push({
        event: 'Postcodecheck',
        eventCategory: 'Postcodecheck',
        eventAction: 'hit',
        eventLabel: 'postcode - ' + postalCode,
        eventZipCodePositive: true,
        eventScenario: data.flow
      })
    },

    redirectToProjectPageDataLayerPush: function(url = '', isPositive = false, flow = '') {
      if (typeof window.dataLayer === 'undefined') {
        return
      }

      window.dataLayer.push({
        event: 'Postcodecheck',
        eventCategory: 'Postcodecheck',
        eventAction: this.$store.getters.previousDataLayerLabel ?? 'hit',
        eventLabel: 'project - ' + url,
        eventPositive: isPositive,
        eventScenario: flow
      })

      this.previousLabel = null
    },

    decisionApiDataLayerPush: function(selectedAddress, postalCode, data) {
      if (typeof window.dataLayer === 'undefined') {
        return
      }

      this.$store.commit('previousDataLayerLabel', `adres - ${selectedAddress}, ${postalCode}`)

      window.dataLayer.push({
        event: 'Postcodecheck',
        eventCategory: 'Postcodecheck',
        eventAction: 'hit',
        eventLabel: `adres - ${selectedAddress}, ${postalCode}`,
        eventAddressPositive: data.is_positive ?? undefined,
        eventAreaStatus: data?.address?.area?.status ?? undefined,
        eventScenario: data.flow ?? undefined,
        eventKnownInAc: data.is_known_in_ac ?? undefined,
        eventEnable: data.is_enabled ?? undefined,
        eventOrderable: data.is_orderable ?? undefined
      })
    }
  }
}
