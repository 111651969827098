<template>
  <div>
    <FlowLayout :data="data" :secondary-cta-text="__('address-check-widget.reset')" />
  </div>
</template>

<script>
import FlowLayout from './FlowLayout'

export default {
  name: 'ZipNotInBad',
  components: {
    FlowLayout
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>
