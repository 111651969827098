<template>
  <div class="mb-last-0">
    <div v-if="data.content">
      <h2>{{ data.content }}</h2>
    </div>

    <div class="button-group">
      <a
        v-if="ctaText && ctaUrl"
        :href="ctaUrl"
        class="btn btn--primary"
        @click.prevent="primaryCtaActionDecider"
      >
        {{ ctaText }}
      </a>

      <a
        v-if="secondaryCtaText"
        type="button"
        class="btn btn--secondary"
        :href="secondaryCtaUrl"
        @click.prevent="secondaryCtaActionDecider"
      >
        {{ secondaryCtaText }}
      </a>
    </div>
  </div>
</template>

<script>
import { EventBus } from '../../../../common/mixins/EventBus'

export default {
  name: 'FlowLayout',
  props: {
    data: {
      type: Object,
      required: true
    },
    ctaText: String,
    ctaUrl: String,
    secondaryCtaText: String,
    secondaryCtaUrl: String
  },
  methods: {
    primaryCtaActionDecider: function () {
      EventBus.$emit('primaryCtaEvent', {
        ...this.data,
        redirectUrl: this.ctaUrl
      })
    },
    secondaryCtaActionDecider: function () {
      if (!this.secondaryCtaUrl) {
        this.$store.commit('resetFlag', true)
        return
      }

      EventBus.$emit('secondaryCtaEvent', {
        ...this.data,
        redirectUrl: this.secondaryCtaUrl
      })
    }
  }
}
</script>
