<template>
  <div id="app" ref="appRef">
    <div v-if="appLoaded">
      <resize-observer @notify="handleResize" />

      <!-- PostalCodeCheck -->
      <PostalCodeCheck
        v-if="showPostalCodeCheck"
        @handlePostalCode="handlePostalCode"
        class="component-container"
      />
      <!-- AddressCheck -->
      <AddressCheck
        v-if="showAddressCheck"
        :postal-code="postalCode"
        @handleAddress="handleAddress"
        class="component-container"
      />

      <!-- Flows -->
      <div v-if="showFlow" class="component-container component-container--dark">
        <component :is="data.component" :data="data" />
      </div>
    </div>
  </div>
</template>

<script>
import AddressCheck from './components/AddressCheck'
import PostalCodeCheck from './components/PostalCodeCheck'
import store from './store/index'

import ZipNotInBad from './components/Flows/ZipNotInBad'

import { ResizeObserver } from 'vue-resize'
import debounce from 'debounce'

import DataLayer from '../../common/mixins/DataLayer'
import Localization from '../../common/mixins/Localization'
import Call from '../../common/api/call'
import Vue from 'vue'
import { EventBus } from '../../common/mixins/EventBus'

Vue.mixin(DataLayer)
Vue.mixin(Call)
Vue.mixin(Localization)

export default {
  store,
  name: 'App',
  components: {
    AddressCheck,
    PostalCodeCheck,
    ZipNotInBad,
    ResizeObserver
  },
  props: {
    apiToken: {
      type: String,
      required: false,
      default: '978470b69d33ab6f508182e15ef2d2fdac908536cde852af667d9c34cc786aa5'
    },
    language: {
      type: String,
      required: false,
      default: 'nl'
    },
    widgetType: {
      type: String,
      required: false,
      default: null
    },
    widgetEventId: {
      type: String,
      required: false,
      default: null
    },
    widgetEventRedirect: {
      type: String,
      required: false,
      default: null
    },
    widgetUrl: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      connectionEstablished: false,
      postalCode: null,
      settingsLoaded: false,
      localizationLoaded: false,
      data: { flow: null, statusText: null },
      showFlow: false,
      showAddressCheck: false,
      showPostalCodeCheck: true
    }
  },
  mounted: function () {
    this.$store.commit('token', this.apiToken)
    this.$store.commit('locale', this.language)

    this.$store.commit('widgetType', this.widgetType)
    this.$store.commit('widgetUrl', this.widgetUrl)
    this.$store.commit('widgetEventId', this.widgetEventId)
    this.$store.commit('widgetEventRedirect', this.widgetEventRedirect)

    this.fetchLocalizationStrings()
    this.fetchSettings()

    EventBus.$on('primaryCtaEvent', (data) => {
      this.redirectUserToPage(data.redirectUrl, data)
    })

    EventBus.$on('secondaryCtaEvent', (data) => {
      this.redirectUserToPage(data.redirectUrl, data)
    })
  },
  beforeDestroy() {
    EventBus.$off('primaryCtaEvent')
    EventBus.$off('secondaryCtaEvent')
  },
  methods: {
    fetchLocalizationStrings() {
      this.httpGet('/localization-string/address-check').then((response) => {
        if (response.status === 200) {
          this.$store.commit('localizationStrings', response.data)
          this.localizationLoaded = true
        }
      })
    },
    fetchSettings() {
      this.httpGet('/settings').then((response) => {
        if (response.status === 200) {
          this.$store.commit('settings', response)
          this.settingsLoaded = true
        }
      })
    },
    handleAddress(data) {
      this.data = data

      this.redirectUserToPage(data.redirect_url, data)
    },
    redirectUserToPage(redirectUrl, data) {
      if (data) {
        this.setLocalStorageValues(data)
      }

      if (redirectUrl) {
        if (localStorage.getItem('widget_url')) {
          redirectUrl += '/' + localStorage.getItem('widget_url')
        }

        this.redirectToProjectPageDataLayerPush(redirectUrl, data.is_positive, data.flow)
        window.location.assign(redirectUrl)
      }
    },
    handlePostalCode(data, postalCode) {
      this.postalCode = postalCode
      this.showPostalCodeCheck = false
      this.data = data

      if (this.data.flow === 'ZipNotInBad') {
        this.showFlow = true
        this.negativeFlowDataLayerPush(postalCode, this.data)
        return
      }

      this.positiveDataLayerPush(postalCode, this.data)
      this.showAddressCheck = true
    },
    setLocalStorageValues(data) {
      localStorage.clear()

      if (data?.best_address_id !== undefined) {
        let key = 'best_address_id'
        if (data.best_address_id.startsWith('dhid')) {
          key = 'dhid'
        }

        localStorage.setItem(key, data.best_address_id)
      }

      if (data?.selected_municipality !== undefined) {
        localStorage.setItem('selected_municipality', data.selected_municipality)
      }

      if (data?.selected_address !== undefined) {
        localStorage.setItem('selected_address', data.selected_address)
      }

      if (data?.selected_postalcode !== undefined) {
        localStorage.setItem('selected_postalcode', data.selected_postalcode)
      }

      if (data?.address?.dhid !== undefined) {
        localStorage.setItem('dhid', data.address.dhid)
      }

      if (data?.address?.street !== undefined) {
        localStorage.setItem('street', data.address.street)
      }

      if (data?.address?.house_number !== undefined) {
        let housenumber = data.address.house_number

        if (data?.address?.box) {
          housenumber = `${housenumber}, ${data.address.box}`
        }

        localStorage.setItem('houseNumber', housenumber)
      }

      if (data?.address?.postal_code !== undefined) {
        localStorage.setItem('postalCode', data.address.postal_code)
      }

      if (data?.address?.municipality !== undefined) {
        localStorage.setItem('municipality', data.address.municipality)
      }

      if (data?.address?.orderable !== undefined) {
        localStorage.setItem('orderable', data.address.orderable)
      }

      if (data?.address?.enable !== undefined) {
        localStorage.setItem('enable', data.address.enable)
      }

      if (data?.address?.area?.status !== undefined) {
        localStorage.setItem('area-status', data.address.area.status)
      }

      if (data?.secondary_cta_url !== undefined) {
        localStorage.setItem('secondary-cta-url', data.secondary_cta_url)
      }

      if (this.$store.getters.widgetType) {
        localStorage.setItem('widget_type', this.$store.getters.widgetType)
      }

      if (this.$store.getters.widgetUrl) {
        localStorage.setItem('widget_url', this.$store.getters.widgetUrl)
      }

      if (this.$store.getters.widgetEventId) {
        localStorage.setItem('widget_event_id', this.$store.getters.widgetEventId)
      }

      if (this.$store.getters.widgetEventRedirect) {
        localStorage.setItem('widget_event_redirect', this.$store.getters.widgetEventRedirect)
      }
    },
    handleResize: debounce(function () {
      if (!this.$refs.appRef) return

      const width = this.$refs.appRef.clientWidth

      if (width < 540) {
        this.$refs.appRef.style.setProperty('--form-group-width', '100%')
        this.$refs.appRef.style.setProperty('--form-row-align', 'flex-start')
        this.$refs.appRef.style.setProperty('--form-row-direction', 'column')
        return
      }

      this.$refs.appRef.style.setProperty('--form-group-width', null)
      this.$refs.appRef.style.setProperty('--form-row-align', null)
      this.$refs.appRef.style.setProperty('--form-row-direction', null)
    }, 200),
    resetWidget() {
      this.showPostalCodeCheck = true
      this.showAddressCheck = false
      this.showFlow = false
      this.postalCode = null
      this.data = { flow: null, statusText: null }
    }
  },
  computed: {
    resetFlag() {
      return this.$store.getters.resetFlag
    },
    appLoaded() {
      return this.settingsLoaded && this.localizationLoaded
    }
  },
  watch: {
    resetFlag(value) {
      if (!value) {
        return
      }

      this.showPostalCodeCheck = true
      this.showAddressCheck = false
      this.showFlow = false
      this.postalCode = null
      this.data = { flow: null, statusText: null }

      this.$store.commit('resetFlag', false)

      this.resetDataLayer('Doe een nieuwe check')
    }
  }
}
</script>

<style lang="scss">
@import 'vue-resize/dist/vue-resize.css';
@import '@/assets/sass/app.scss';

#app {
  --text-color: #{$primary};
  --text-color-disabled: #{rgba($primary, 0.6)};
  --focus-color: #{$primary};
}

.component-container {
  padding: 20px;
  background-color: $transparent-white;
  border-radius: $border-radius;

  &--dark {
    --text-color: #{$white};
    --focus-color: #{$white};

    background-color: $primary;
  }
}
</style>
