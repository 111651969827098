<template>
  <div>
    <h2>{{ __('address-check-widget.check postal code title') }}</h2>

    <form @submit.prevent="submitForm">
      <div class="form-group small input-moving-label">
        <input
          id="postal_code"
          v-model="postalCode"
          class="form-control"
          :placeholder="__('address-check-widget.postal code placeholder')"
          required
        />

        <label for="postal_code">
          {{ __('address-check-widget.postal code label') }}
        </label>
      </div>

      <button type="submit" class="btn btn--primary">
        {{ __('address-check-widget.check postal code') }}
      </button>
    </form>
  </div>
</template>

<script>
export default {
  name: 'PostalCodeCheck',
  data() {
    return {
      postalCode: null,
      flow: null
    }
  },
  methods: {
    submitForm() {
      this.checkPostalCode(this.postalCode).then((response) => {
        if (response.status === 200) {
          const { data } = response

          this.$emit('handlePostalCode', data, this.postalCode)
        }
      })
    }
  }
}
</script>
