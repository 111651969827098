import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    token: null,
    locale: 'nl',
    localizationStrings: [],
    settings: [],
    resetFlag: false,
    previousDataLayerLabel: null,
    widgetType: null,
    widgetEventId: null,
    widgetEventRedirect: null,
    widgetUrl: null
  },
  getters: {
    token(state) {
      return state.token
    },
    locale(state) {
      return state.locale
    },
    localizationStrings(state) {
      return state.localizationStrings
    },
    settings(state) {
      return state.settings
    },
    setting:
      (state) =>
      (key, fallback = null) => {
        return state.settings[key] ?? fallback
      },
    resetFlag(state) {
      return state.resetFlag
    },
    previousDataLayerLabel(state) {
      return state.previousDataLayerLabel
    },
    widgetType(state) {
      return state.widgetType
    },
    widgetEventId(state) {
      return state.widgetEventId
    },
    widgetEventRedirect(state) {
      return state.widgetEventRedirect
    },
    widgetUrl(state) {
      return state.widgetUrl
    }
  },
  mutations: {
    token(state, newValue) {
      state.token = newValue
    },
    locale(state, newValue) {
      state.locale = newValue
    },
    localizationStrings(state, newValue) {
      state.localizationStrings = newValue
    },
    settings(state, newValue) {
      state.settings = newValue.data
    },
    resetFlag(state, newValue) {
      state.resetFlag = newValue
    },
    previousDataLayerLabel(state, newValue) {
      state.previousDataLayerLabel = newValue
    },
    widgetType(state, newValue) {
      state.widgetType = newValue
    },
    widgetUrl(state, newValue) {
      state.widgetUrl = newValue
    },
    widgetEventId(state, newValue) {
      state.widgetEventId = newValue
    },
    widgetEventRedirect(state, newValue) {
      state.widgetEventRedirect = newValue
    }
  },
  actions: {},
  modules: {}
})
