export default {
  methods: {
    __: function(key, replace = []) {
      return this.fetchString(key, replace)
    },

    __html: function(key, replace = []) {
      return this.fetchString(key, replace)
    },

    fetchString: function(key, replace = []) {
      const translations = this.$store.getters.localizationStrings
      let translation = translations[key] ? translations[key] : key

      const length = replace.length
      if (typeof length === 'number' && length >= 0) {
        for (let i = 0, length = replace.length; i < length; i++) {
          translation = translation.replace(':' + i, replace[i])
        }
      } else {
        const keys = Object.keys(replace)
        for (let i = 0, length = keys.length; i < length; i++) {
          translation = translation.replace(':' + keys[i], replace[keys[i]])
        }
      }

      return translation
    }
  }
}
