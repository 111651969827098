<template>
  <div>
    <h2>{{ __('address-check-widget.check address title') }}</h2>

    <p>{{ __('address-check-widget.check address intro') }}</p>

    <form @submit.prevent="checkAvailability">
      <div class="form-row">
        <div class="form-group small input-moving-label">
          <input
            id="postal_code"
            :placeholder="__('address-check-widget.postal code placeholder')"
            :value="postalCode"
            class="form-control"
            readonly
          />
          <label for="postal_code">
            {{ __('address-check-widget.postal code label') }}
          </label>
        </div>

        <div ref="vueSelectComponentWrapper" class="form-group flex-1">
          <label for="address">{{ __('address-check-widget.address label') }}</label>
          <VueMultiselect
            v-model="selectedAddress"
            :clear-on-select="false"
            :custom-label="addressLabel"
            :internal-search="false"
            :loading="loading"
            :options="addresses"
            :options-limit="300"
            :placeholder="__('address-check-widget.address placeholder')"
            :preselect-first="true"
            :preserve-search="true"
            :showLabels="false"
            aria-autocomplete="none"
            open-direction="bottom"
            @search-change="debouncedSearch"
          >
            <span slot="noOptions" class="red-link">
              <p>
                {{ __('address-check-widget.no options') }}
                <a v-on:click.stop="redirectToUrl(addressNotFoundCtaLink)">{{
                  __('address-check-widget.more information')
                }}</a>
              </p>
            </span>

            <span slot="noResult" class="red-link">
              <p>
                {{ __('address-check-widget.no options') }}
                <a v-on:click.stop="redirectToUrl(addressNotFoundCtaLink)">{{
                  __('address-check-widget.more information')
                }}</a>
              </p>
            </span>
          </VueMultiselect>
        </div>
      </div>

      <button :disabled="isDisabled" class="btn btn--primary" type="submit">
        {{ __('address-check-widget.check address') }}
      </button>
    </form>
  </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect'
import debounce from 'lodash.debounce'

export default {
  name: 'AddressCheck',
  components: {
    VueMultiselect
  },
  props: {
    postalCode: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      addresses: [],
      selectedAddress: null,
      statusText: '',
      loading: false,
      tempSearchAddress: null
    }
  },
  computed: {
    isDisabled() {
      return this.postalCode === null || this.selectedAddress === null
    },
    addressNotFoundCtaLink() {
      return this.$store.getters.setting('address_not_found_cta_link')
    }
  },
  mounted() {
    this.postalCodeInput = this.postalCode
    this.search('')
    this.$refs.vueSelectComponentWrapper?.querySelector?.('input')?.focus?.()
  },
  methods: {
    debouncedSearch: debounce(
      function (data) {
        this.search(data)
      },
      150,
      this
    ),
    redirectToUrl(redirectUrl) {
      localStorage.clear()
      this.decisionApiDataLayerPush(this.tempSearchAddress, this.postalCode, {
        is_positive: false
      })
      this.redirectToProjectPageDataLayerPush(redirectUrl, false)
      window.location.assign(redirectUrl)
    },
    async search(data) {
      this.tempSearchAddress = data
      this.loading = true
      try {
        const response = await this.httpGet(
          'address/search?search=' + data + '&postal-code=' + this.postalCode
        )

        this.addresses = response.data.data
        // eslint-disable-next-line no-empty
      } catch (error) {}
      this.loading = false
    },
    addressLabel(option) {
      return option.address
    },
    checkAvailability() {
      try {
        this.httpGet(`decision/${this.selectedAddress.id}`, {
          address: this.selectedAddress.address_nl,
          postalCode: this.postalCode
        }).then((response) => {
          if (response.status === 200) {
            const { data } = response

            this.decisionApiDataLayerPush(this.selectedAddress.address, this.postalCode, data)
            this.$emit('handleAddress', {
              ...response.data,
              ...{
                best_address_id: this.selectedAddress.id,
                selected_address: this.selectedAddress.address,
                selected_postalcode: this.selectedAddress.postal_code,
                selected_municipality: this.selectedAddress.municipality
              }
            })
          }
        })
        // eslint-disable-next-line no-empty
      } catch (error) {}
    }
  }
}
</script>
