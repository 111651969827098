import http from './http'

export default {
  methods: {
    checkPostalCode (postalCode) {
      return this.httpGet(`/decision/postal-code?postal-code=${postalCode}`)
    },

    httpGet (url, params = {}) {
      const config = {
        headers: {
          Authorization: this.token(),
          Locale: this.locale()
        },
        params: params
      }

      return http.get(`${url}`, config)
    },

    token () {
      return this.$store.getters.token
    },

    locale () {
      return this.$store.getters.locale
    }
  }
}
